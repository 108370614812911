import React, { } from 'react';
import styled from 'styled-components';

import Header from 'components/layout/header/header';
import MainMenuDrawer from 'components/layout/mainMenuDrawer/mainMenuDrawer'

import { bp } from 'settings/theme';
import HelpModal from 'components/common/modal/helpModal';
import Modal from 'components/common/modal/modal';
import useLayout from 'providers/layout/layoutProvider';


type Props = {
  templateData: TemplateData,
  children?: React.ReactNode
};

// Component
const HomepageLayout: React.FC<Props> = (props) => {

  const { isHelpModalOpen, setIsHelpModalOpen } = useLayout();
  // Handle size chart  modal open change
  const handleIsHelpModalOpenChange = (val) => {
    setIsHelpModalOpen(val);
  }
  return (
    <StyledLayout className="mainLayout">

      <div className="mainLayout-mainMenuDrawer">
        <MainMenuDrawer topLevelCategories={props.templateData?.topLevelCategories.find((category) => category.slug === "all-products")} />
      </div>

      <div className="mainLayout-header">
        <Header />
      </div>

      <div className="mainLayout-main">
        <div className="mainLayout-page">
          {props.children}
        </div>
      </div>

      <Modal isOpen={isHelpModalOpen} setIsOpen={handleIsHelpModalOpenChange}>
        <HelpModal isOpen={isHelpModalOpen} setIsOpen={handleIsHelpModalOpenChange} />
      </Modal>
    </StyledLayout>
  );
};

export default HomepageLayout;



const StyledLayout = styled.div`

  --header-height: 3.5rem;

  overflow-x: hidden;
  width: 100%;
  min-height: 100vh;

  .mainLayout-mainMenuDrawer{
    z-index: 2;
    flex: 0 0 auto;
    width: 0;
  }

  .mainLayout-header{
    position: relative;
    z-index: 1;
  }

  .mainLayout-main{
    min-height: calc(100svh - var(--header-height));
    min-height: calc(100vh - var(--header-height));
  }

  .mainLayout-page{
    flex: 1 1 auto;
    position: relative;
    z-index: 0;
    background-color: var(--primary-primary-90, #201F1D);
  }

  .mainLayout-footer{
    flex: 0 0 auto;
  }

  @media (min-width: ${bp(3)}) {
    --header-height: 5rem;
  }

`;
