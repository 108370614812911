import { ReactElement, Fragment, useState, useMemo, useEffect } from 'react';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';
import { createServerSideHelpers } from '@trpc/react-query/server';
import { DehydratedState } from '@tanstack/react-query';
import { createContext } from 'server/context';
import { Category } from '@prisma/client';

import superJson from 'utils/superJson';
import { appRouter } from 'server/routers/_app';
import getTemplateData from 'utils/getTemplateData';

import HomepageLayout from 'components/layout/homepageLayout';
import MaxWidthWrapper from 'components/layout/maxWidthWrapper';


import { bp } from 'settings/theme';
import Footer from 'components/layout/footer/footer';
import GrowerPreviewCard from 'components/growers/growerCard';
import { trpc } from 'utils/trpc';
import HarvestPreviewCard from 'components/harvests/harvestPreviewCard';
import { getGrowerName } from './harvests';


type Props = {
  templateData: TemplateData;
  subCategories: Category[];
  trpcState: DehydratedState;
  growersString: string;
  page: number;
  harvestsString: string;
};

const HomePage: NextPageExtended<Props> = (props) => {
  const [growersPages, setGrowersPages] = useState<Record<string, ParsedGrowerDataV2[]>>({});
  const [growersPagesLoaded, setGrowersPagesLoaded] = useState(false);
  const [harvestsPages, setHarvestsPages] = useState<Record<string, ParsedHarvestDataV2[]>>({});

  const [harvestsPagesLoaded, setHarvestsPagesLoaded] = useState(false);

  const harvestsQuery = trpc.harvests.get.useQuery({ store_id: parseInt(process.env.NEXT_PUBLIC_STORE_ID as string) });

  const harvests = harvestsQuery.data;
  const growersQuery = trpc.growers.get.useQuery({ store_id: parseInt(process.env.NEXT_PUBLIC_STORE_ID as string) });
  // const totalCount = productsQuery.data?.meta?.total_count ?? 0;
  const growers = growersQuery.data;
  const growersResults = useMemo(() => {
    // Convert the productPages object into an array of page objects with the page number as a key
    let growersPagesArray = Object.entries(growersPages).map(([page, growers]) => {
      return {
        page: parseInt(page),
        growers,
      }
    });

    // Sort the array by page number
    growersPagesArray = growersPagesArray.sort((a, b) => a.page - b.page);

    // Return the array of page objects
    return growersPagesArray;

  }, [growersPages]);
  useEffect(() => {
    const newGrowersPages = {
      ...growersPages,
      [props.page]: growers ?? [],
    }
    setGrowersPages(newGrowersPages);
    setGrowersPagesLoaded(true);
  }, [growers]);

  const growersIndex = useMemo(() => {
    if (!growers) return 0;
    return Math.floor(Math.random() * growers.length)
  }, [props.growersString])

  const harvestsIndex = useMemo(() => {
    if (!harvests) return 0;
    return Math.floor(Math.random() * harvests.length)
  }, [props.harvestsString])


  // Every time we get new results, add them to the harvestsPages object
  useEffect(() => {
    const newHarvestsPages = {
      ...harvestsPages,
      [props.page]: harvests ?? [],
    }
    setHarvestsPages(newHarvestsPages);
    setHarvestsPagesLoaded(true);
  }, [harvests]);

  const harvestsResults = useMemo(() => {
    // Convert the productPages object into an array of page objects with the page number as a key
    let harvestsPagesArray = Object.entries(harvestsPages).map(([page, harvests]) => {
      return {
        page: parseInt(page),
        harvests,
      }
    });

    // Sort the array by page number
    harvestsPagesArray = harvestsPagesArray.sort((a, b) => a.page - b.page);

    // Return the array of page objects
    return harvestsPagesArray;

  }, [harvestsPages]);

  return (
    <>

      <Head>
        <title>Eden - Transparent Greens</title>
      </Head>

      <StyledHomePage className="homePage">
        <div className='homePage-intro'>
          <div className='homePage-container'>
            <h3>Discover<br />the growers of</h3>
            <h1>Thailand</h1>
            <Link href='#How-Section'>I am a Grower</Link>
          </div>
        </div>

        <MaxWidthWrapper className='homePage-frame' id="Growers">
          <h2>Growers</h2>
          {growersPagesLoaded && growers && (
            <GrowerPreviewCard grower={growers[growersIndex]} />
          )}
        </MaxWidthWrapper>
        <hr />
        <MaxWidthWrapper className='homePage-frame' id="Strains">
          <h2>Strains</h2>
          {harvestsPagesLoaded && harvests && (
            <HarvestPreviewCard harvest={harvests[harvestsIndex]} growerName={getGrowerName(harvests[harvestsIndex].harvestKey)} />
          )}
        </MaxWidthWrapper>

        <Footer />
      </StyledHomePage>

    </>
  );

}

export const getStaticProps = async ({ params }) => {

  const ssg = createServerSideHelpers({
    router: appRouter,
    ctx: await createContext(),
    transformer: superJson
  });


  const growersQuery = ssg.growers.get.fetch({ store_id: parseInt(process.env.NEXT_PUBLIC_STORE_ID as string) });
  const harvestsQuery = ssg.harvests.get.fetch({ store_id: parseInt(process.env.NEXT_PUBLIC_STORE_ID as string) });

  const results = await Promise.all([
    getTemplateData(),
    growersQuery,
    harvestsQuery
  ]);

  const templateData = results[0];
  const growers = results[1]
  const harvests = results[2];


  return {
    props: {
      templateData,
      // category,
      page: 1,
      harvestsString: superJson.stringify(harvests),
      growersString: superJson.stringify(growers),
      trpcState: ssg.dehydrate(),
    },
    revalidate: 1
  };

};

// export const getStaticPaths: GetStaticPaths<{ slug: string }> = async () => {
//   return {
//       paths: [], //indicates that no page needs be created at build time
//       fallback: 'blocking' //indicates the type of fallback
//   };
// };

HomePage.getLayout = (page: ReactElement) => {
  return (
    <HomepageLayout templateData={page.props.templateData}>
      {page}
    </HomepageLayout>
  )
}

export default HomePage;

const StyledHomePage = styled.div`
  
  --eden-blue: #CCFFF1;
  color: var(--primary-primary-0, #F7F2ED);
  
  &>div{
    padding: 0 0.75rem;
    padding-bottom: 3.5rem;
  }

  h1{
    font-size: 3.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3.25rem;
  }
  h3{
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; 
  }

  hr{
    margin-top: 6rem;
    width: 30%;
  }

  .homepageButtonWrapper{
    width: 100%;
    margin-top: 2.1875rem;

    button{
      background: #262626;
      color: #fff;
      width: 100%;
      border-radius: 1.875rem;
      height: 3.25rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.1875rem;
    }
  }

  .homePage-intro{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: calc(100vh - var(--header-height));
    padding-bottom: 0;
    background-image: url("/images/homepage-hero-background.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    a{ 
      background: var(--primary-primary-10, #EEE6DD);
      height: 2.25rem;
      padding: 0.5rem 0.75rem;
      border-radius: 0.25rem;
      overflow: hidden;
      color: #141313;
      /* UI/Mobile/m-body1-bold */
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;    
      width: 50%;
      text-align: center;
    }
  }
  
  .homePage-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 1.5rem;
  }

  .homePage-frame{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5rem 1.5rem;
    gap: 1.5rem;
    height: 90vh;
    
  }


  @media (min-width: ${bp(3)}) {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    h1{
      font-size: 6rem;
      line-height: 100%;
    }
    
    h2{
      font-size: 3.25rem;
      line-height: 120%;
      margin: 0 auto;

    }

    hr{
    margin-top: 6rem;
    width: 10%;
  }

    .homePage-intro{
      flex-grow: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      a{
        width: 20%;
      }
    }

    .homePage-container{
      max-width: 75rem;
    }
  }

`;