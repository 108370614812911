import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';


import theme, { bp, color } from 'settings/theme';
import { toCamelCase } from 'server/utils/format';


type Props = {
  grower: ParsedGrowerDataV2;
};

// Component
const GrowerPreviewCard: React.FC<Props> = (props) => {

  return (
    <>
      {props.grower ? (
        <StyledGrowerPreviewCard $backgroundURI={props.grower.growerPreviewImages[0].uri} className="growerCard">


          <div className="growerCard-details">
            <div className='growerCard-meta'>
              <h3>
                {props.grower.growerName}
              </h3>
              <h2>
                {props.grower.farmName}
              </h2>
              <h5>
                {props.grower.growerCity}, Thailand
              </h5>
            </div>
            <div className='growerCard-details-content'>
              <div className="growerCard-details-description">
                <p>{`${1} Strain${1 > 1 ? 's' : ''} Registered`}</p>
                {/* <p>{`${props.grower.growerTotalHarvests} Strain${props.grower.growerTotalHarvests > 1 ? 's' : ''} Registered`}</p> */}
              </div>
            </div>

            <Link href={`/grower/${toCamelCase(props.grower.growerName).toLowerCase()}`}>
              Learn More
            </Link>
          </div>
        </StyledGrowerPreviewCard>
      ) : (
        null
      )
      }

    </>
  );
};

export default GrowerPreviewCard;


const StyledGrowerPreviewCard = styled.div<{ $backgroundURI: string }>`
  display: flex;
  width: 100%;
  aspect-ratio: 1 / 1;
  color: var(--primary-primary-0, #F7F2ED);
  background: url(${props => props.$backgroundURI});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .growerCard-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    gap: 1rem;
    padding: 0.75rem 0.75rem;
    color: var(--primary-primary-0, #F7F2ED);

    ul{
      display: flex;
      flex-direction: column;
      list-style: none;
      padding: 0;
      margin: 0;

      color: var(--primary-primary-20, #D9CCBF);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; 

    }

    a{ 
      background: var(--primary-primary-10, #EEE6DD);
      height: 2.25rem;
      padding: 0.5rem 0.75rem;
      border-radius: 0.25rem;
      overflow: hidden;
      color: #141313;
      /* UI/Mobile/m-body1-bold */
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;    
      width: 50%;
      text-align: center;
    }

    h2{
      font-size: 3.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 4rem; /* 230.769% */
    }

    h3{
    /* UI/Mobile/m-H6 */
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; 
    }

    h5{
      /* UI/Mobile/m-body2 */
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 1.1375rem */
      margin-bottom: 0.5rem;

    }
    .harvestCard-details-description{
      font-size: 0.925rem;
      line-height: 1.2rem;
      font-weight: 400;
    }

    .harvestCard-details-new{
      padding: 0.4rem 0.9rem;
      color: ${color.gray(100)};
      width: fit-content;
      font-size: 0.75rem;
      font-weight: 400;
      border-radius: 1rem;
      background-color: #23CD80;
    }

    .harvestCard-details-view{
      text-decoration: underline;
    }

    .harvestCard-details-price{
      font-size: 0.925rem;
      font-weight: 350;
    }

    .harvestCard-details-convertedPrice{
      font-size: 0.8rem;
    }

  }

  .growerCard-buttons{
    flex: 0 0 auto;
    padding-top: 1rem;

    button.growerCard-button{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 0.5rem;
      width: 100%;
      height: auto;
      line-height: 1rem;
      padding: 0.55rem 0.75rem;
      border: none;
      border-radius: 1rem;
      background-color: ${color.gray(18)};
      transition: background-color 0.15s ease-in-out;

      &:hover{
        background-color: ${color.gray(50)};
      }

      &.growerCard-button--ghost{
        border: 1px solid ${color.gray(40)};
        background-color: transparent;

        &:hover{
          background-color: ${color.gray(20)};
        }
      }
    }
  }

  @media (min-width: ${bp(3)}) {


    .growerCard-details{
      padding: 1.5rem 1.5rem;
      
      .growerCard-details-content{
        margin-top: 2rem;
      }
    }

  }
`;
